import React from "react"
import Layout from "../components/layout-pdf"
import Seo from "../components/seo"
import PDF from "../components/pdfTemplate"
const Template = () => {
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
    return (
      <Layout>
        <Seo title="Ops Guide" />
        <div id="id1"></div>
        <PDF title="Tournament G." data={"https://hello.archermalmo.com/wc/json/Sheet_198241531_OPS.json?noCache=" + getRandomInt(9999)} isOps />
        <hr className="my-4" />
        <PDF title="Earn and Get" data={"https://hello.archermalmo.com/wc/json/Sheet_198241531_OPS.json?noCache=" + getRandomInt(9999)} isOps />
        <hr className="my-4" />
        <PDF title="Come and Get" data={"https://hello.archermalmo.com/wc/json/Sheet_198241531_OPS.json?noCache=" + getRandomInt(9999)} isOps />
      </Layout>
    )
};
export default Template





